import React from 'react';

function Component(props) {
    let count = 0;

    props.data.skill_lines.map(skillLine => count += props.skillPoints(skillLine.id))

    return (
        <div className="submenu-item">
            <button className="btn" value={props.id} onClick={(e) => props.selectClass(props.id, props.name, props.slug)}>{props.name}</button>
            <span className="number">{count > 0 ? count : '' }</span>
        </div>
    );
}

export default Component;
