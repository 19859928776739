import React from 'react';

function Component(props) {

    return (
        <div className="skill-line-title">
            <div className={`icon class ${props.class.toLowerCase().replace(' ', '-')}`}></div>
            <h1 className="title">{props.class.replace('-', ' ')} <span className="subtitle">{props.name}</span></h1>
        </div>
    );
}

export default Component;
