import React from 'react';

function Component(props) {
    return (
        <div className="submenu-item">
            <button className="btn" value={props.id} onClick={props.handleClick}>{props.name}</button>
            <span className="number">{props.skillPoints(props.id) > 0 ? props.skillPoints(props.id) : ''}</span>
        </div>
    );
}

export default Component;
