import React from 'react';

function Component() {

    return (
        <main className="skill-line-details home">
            <h1>Welcome to ESO Builder</h1>
            <h2>The skill point calculator for The Elder Scrolls Online</h2>
            <p>The Elder Scrolls Online is a great game that I strongly recommend everyone experience all the way through at least once however sometimes you just want to know how many skill points your build needs and just work towards that goal.</p>
            <p>Simply select a class and the skills you're interested in you'll see how many skill points you need.</p>

            <div className="highlight">
                I would love to be able to add full details for every skill so if anyone knows of an Elder Scrolls Online api for skill information please <a href="mailto:info@esobuilder.com">let me know</a>.
            </div>
        </main>
    )
}

export default Component;
