import React, { useEffect, useState } from 'react';

import Title from "./Title";
import Skill from "./Skill";
import Passive from "./Passive";

function Component(props) {
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [{ data, updated }, setDataObject] = useState({
        data: [],
        updated: false
    });

    // when chosen skill line changes
    useEffect(() => {
        // load skills into state from session, if available
        // otherwise clear selected skills to avoid propogation from previous skill line selection
        if (localStorage.getItem(props.skillLine)) {
            setSelectedSkills(selectedSkills => JSON.parse(localStorage.getItem(props.skillLine)));
        } else {
            setSelectedSkills(selectedSkills => []);
        }

        const loadData = async (skill) => {
            const url = `./data/${skill}.json`;
            try {
                const response = await fetch(url);
                const data = await response.json();
                setDataObject({ data: data, updated: true });
            } catch(err) {
                setDataObject({ data: null, updated: false });
            }
        }
        // load data into state
        loadData(props.skillLine);
    }, [props.skillLine]);

    // update session and skill points count when skills change
    useEffect(() => {
        localStorage.setItem(props.skillLine, JSON.stringify(selectedSkills));
        // remove session veraible if no skills selected
        if (!selectedSkills.length) {
            localStorage.removeItem(props.skillLine);
        }
        // force nav to update skill points
        props.setUpdateCount(!props.updateCount)
    }, [selectedSkills, props.skillLine]);

    // select/deselect skill
    const updateSelectedSkills = (skillID) => {
        if (!selectedSkills.includes(skillID)) {
            // add
            setSelectedSkills(selectedSkills => [...selectedSkills, skillID]);
        } else {
            // remove
            setSelectedSkills(selectedSkills => selectedSkills.filter(selectedID => !selectedID.includes(skillID)));
        }
    }

    // reset all skills
    const resetSelections = (e) => {
        e.preventDefault();
        setSelectedSkills(selectedSkills => []);
        window.scrollTo(0,0);
    }

    if (!updated) {
        return (
            <main className="skill-line-details">
                <div className="error">There was a problem retrieving the skills</div>
            </main>
        );
    } else {
        return (
            <main className="skill-line-details">

                <Title class={data.class} name={data.name} />

                <div className="skills">

                    {data.ultimates &&
                        <div className="skills-type ultimate-skills">
                            <h2 className="title">Ultimate skills</h2>
                            {data.ultimates.map((skillData) => {
                                    return (
                                        <Skill key={skillData.id} data={skillData} selectedSkills={selectedSkills}
                                        updateSelectedSkills={updateSelectedSkills} classID={data.class} />
                                    );
                                })
                            }
                        </div>
                    }

                    {data.actives &&
                        <div className="skills-type ultimate-skills">
                            <h2 className="title">Active skills</h2>
                            {data.actives.map((skillData) => {
                                    return (
                                        <Skill key={skillData.id} data={skillData} selectedSkills={selectedSkills}
                                        updateSelectedSkills={updateSelectedSkills} classID={data.class} />
                                    );
                                })
                            }
                        </div>
                    }

                    {data.passives &&
                        <div className="skills-type passive-skills">
                            <h2 className="title">Passive skills</h2>
                            {data.passives.map((skillData) => {
                                    return (
                                        <Passive key={skillData.id} data={skillData} selectedSkills={selectedSkills}
                                        updateSelectedSkills={updateSelectedSkills} classID={data.class} />
                                    );
                                })
                            }
                        </div>
                    }

                </div>

                <div className="clear-selections">
                    <a href="/" className="btn reset" onClick={resetSelections}>Reset Selections</a>
                </div>

            </main>
        );
    }
}

export default Component;
