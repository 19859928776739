import React, { useState, useEffect } from 'react';

function Component(props) {
    let initialLevel = 0;
    props.selectedSkills.map(selectedSkill => {
        if (selectedSkill.includes(props.data.id)) {
            initialLevel = selectedSkill.split(':')[1];
        }
        return null;
    });
    const [level, setLevel] = useState(parseInt(initialLevel));
    const [disabledButton, setDisabledButton] = useState('');

    //  if selected skills is empty (e.g. after reset button pressed)
    useEffect(() => {
        if (props.selectedSkills.length === 0) {
            setLevel(0);
        }
    }, [props.selectedSkills])

    // set plus/minus button disabled true/false
    useEffect(() => {
        if (level === 0) {
            setDisabledButton('minus')
        } else if (level === parseInt(props.data.levels)) {
            setDisabledButton('plus')
        } else {
            setDisabledButton('')
        }
    }, [level, props.data.levels])

    const addLevel = () => {
        let newLevel = level + 1;
        if (newLevel <= props.data.levels) {
            updateSelectedSkills(newLevel);
        }
    }

    const subtractLevel = () => {
        let newLevel = level - 1;
        if (newLevel >= 0) {
            updateSelectedSkills(newLevel);
        }
    }

    const updateSelectedSkills = (newLevel) => {
        setLevel(newLevel);
        // remove old level
        // this is a toggle fucntion so skip if level === 0 otherwise it will add it instead
        if (level) {
            props.updateSelectedSkills(props.data.id + ':' + level);
        }
        // add new level - do not add if newlevel === 0
        // this is the default value adn we don't need to store it
        if (newLevel) {
            props.updateSelectedSkills(props.data.id + ':' + newLevel);
        }
    }

    return (
        <div key={props.data.id} id={props.data.id} className="skill passive" data-max={props.data.levels} data-val={level}>
            <div className="row">
                <button className="btn minus" onClick={subtractLevel} disabled={disabledButton === 'minus' && true}></button>
                <button className="btn plus" onClick={addLevel} disabled={disabledButton === 'plus' && true}></button>
                <img className="icon" src={`/images/icons/${props.data.id}.png`} alt="" />
                <div className="name">{props.data.name}<div className="count">({level} / {props.data.levels})</div></div>
            </div>
        </div>
    );
}

export default Component;
