import React from 'react';

function Component(props) {
    return (
        <div className="submenu-item" onClick={props.changeClass}>
            <button className="btn change">Change Class</button>
        </div>
    );
}

export default Component;
