import React from 'react';

function Component(props) {
    let count = 0;

    props.data.skill_lines.map((skillLine) => (
        count += props.skillPoints(skillLine.id)
    ))

    return (
        <div className="menu-item">
            <div className={`icon ${props.data.slug}`}></div>
            <button className="trigger" onClick={props.handleClick}>{props.data.name || props.name}</button>
            <span className="number">{count > 0 ? count : '' }</span>
        </div>
    );
}

export default Component;
