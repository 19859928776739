import React, { useState, useEffect } from 'react';

function Component(props) {
    const [selected, setSelected] = useState(false);
    const [selectedClass, setSelectedClass] = useState('');

    useEffect(() => {
        if(props.data.id === props.selectedMorph) {
            setSelected(true);
            setSelectedClass('selected');
        } else {
            setSelected(false);
            setSelectedClass('');
        }

        if (props.selectedMorph && props.selectedMorph !== props.data.id) {
            setSelectedClass('not-selected');
        }
    }, [props.selectedMorph, props.data.id])

    // select morph and let main ability know
    const selectMorph = () => {
        if (!selected) {
            setSelected(true);
            props.updateSelectedSkills(props.data.id);
            props.setSelectedMorph(props.data.id);
            props.setMainHasMorph(true);
            // remove previously selected skill from selected skills list
            if (props.selectedMorph) {
                props.updateSelectedSkills(props.selectedMorph);
            }
        }
    }

    return (
        <div className={`skill ${props.data.resource_type} morph ${selectedClass}`}>
            <div className="row">
                <button className="btn" value={props.data.id} onClick={selectMorph}></button>
                <img className="icon" src={`/images/icons/${props.data.id}.png`} alt="" />
                <div className="name">{props.data.name}<div className="type">{props.data.resource_type}</div></div>
            </div>
            <div className="description">{props.data.new_effect}</div>
        </div>
    );
}

export default Component;
