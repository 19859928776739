import React, { useState } from 'react';

import './assets/css/style.scss';

import Header from './components/Header';
import Footer from './components/Footer';
import Nav from './components/nav/Nav';
import Line from './components/skills/Line';
import Home from './components/home/Home';
import SessionDebug from './components/SessionDebug';

function App() {
    // set debug mode on
    // document.getElementById('root').classList.add('debug');
    // set background image to match class selection
    if (localStorage.getItem('classSlug')) {
        document.body.classList.add(localStorage.getItem('classSlug'));
    }

    const initialClassID = localStorage.getItem('classID') || '';
    const initialClassName = localStorage.getItem('className') || '';
    const initialClassSlug = localStorage.getItem('classSlug') || '';
    const [classDetails, setClassDetails] = useState({
        id: initialClassID,
        name: initialClassName,
        slug: initialClassSlug
    });

    const [skillLine, setSkillLine] = useState('');
    const [updateCount, setUpdateCount] = useState([]); // used to force nav skill counts to update

    const toggleNav = (event) => {
        event.preventDefault();
        // hide all submneus
        const menuItems = document.querySelectorAll('.menu-item');
        menuItems.forEach(menuItem => {
            menuItem.classList.remove('active');
        });
        // show/hide nav
        document.getElementById('root').classList.toggle('nav-visible');
        // change burger icon
        document.querySelector('.animated-icon').classList.toggle('open');
    }

    const chooseSkills = (e) => {
        setSkillLine(e.target.value);
        // close nav when skill is selected
        toggleNav(e);
    }

    return (
        <div>
            <Header toggleNav={toggleNav} />
            <div className="container content">
                <div id="nav" className="nav left">
                    <Nav chooseSkills={chooseSkills} skillLine={skillLine} setSkillLine={setSkillLine}
                    updateCount={updateCount} setClassDetails={setClassDetails} classID={classDetails.id}
                    className={classDetails.name} classSlug={classDetails.slug}/>
                </div>
                <div className="right">
                    {/* <SessionDebug /> */}

                    {skillLine ? <Line skillLine={skillLine} setUpdateCount={setUpdateCount} updateCount={updateCount} /> :
                        <Home />}
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default App;
