import React from 'react';

function Component(props) {
    let count = 0;

    props.ClassSkillLines.map(charClass =>
        charClass.skill_lines.map((skillLine) =>
            count += props.skillPoints(skillLine.id)))

    props.SkillLines.map(skillLineType =>
        skillLineType.skill_lines.map((skillLine) =>
            count += props.skillPoints(skillLine.id)))

    return (
        <div className="skill-points-needed">
            <span className="text">Skill points needed</span>
            <span className="number">{count}</span>
        </div>
    );
}

export default Component;
