import React from 'react';

import logo from '../assets/images/logo.svg';

function Component(props) {

    return (
        <header>
            <div className="header-bar">
                <div className="container">
                    <a href="/"><img className="logo" src={logo} alt="" /></a>
                    <button id="nav-trigger" className="nav-trigger" onClick={props.toggleNav}>
                        <span className="animated-icon"><span></span><span></span><span></span><span></span></span>
                    </button>
                </div>
            </div>
        </header>
    );
}

export default Component;
