import React, { useEffect } from 'react';

import SkillPointsNeeded from './SkillPointsNeeded';
import MenuItem from "./MenuItem";
import ClassMenuItem from "./ClassMenuItem";
import SubmenuItem from "./SubmenuItem";
import ClassSubmenuItem from "./ClassSubmenuItem";
import ChangeClass from "./ChangeClass";

import SkillLines from '../../data/SkillLines';
import ClassSkillLines from '../../data/ClassSkillLines';

function Component(props) {

    useEffect(() => {
        // store state in session
        localStorage.setItem('classID', props.classID);
    }, [props.classID]);

    const toggleSubmenu = (event) => {
        event.preventDefault();
        // hide all submneus except selected one
        const menuItems = document.querySelectorAll('.menu-item');
        menuItems.forEach(menuItem => {
            if (menuItem.querySelector('.trigger') !== event.target) {
                menuItem.classList.remove('active');
            }
        });
        // show/hide selected submenu
        event.target.closest('.menu-item').classList.toggle('active');
    }

    // set class and update background
    const selectClass = (id, name, slug) => {
        props.setSkillLine('');
        props.setClassDetails({ id: id, name: name, slug: slug });
        document.body.className = '';
        document.body.classList.add(slug);
        // clear session data belonging to other class skill lines
        ClassSkillLines.map(classData => {
            if (classData.id !== id) {
                classData.skill_lines.map(skillLine => localStorage.removeItem(skillLine.id))
            }
            return null
        })
        // set classID in session
        localStorage.setItem('classID', id);
        localStorage.setItem('className', name);
        localStorage.setItem('classSlug', slug);
    }

    // reset class
    const changeClass = (e) => {
        props.setClassDetails({ id: '', name: '', slug: '' });
        props.setSkillLine('');
    }

    // reset all
    const resetAll = (e) => {
        localStorage.clear();
        window.location.reload();
    }

    const skillPoints = (line) => {
        let skillPointsCount = 0;
        const skills = JSON.parse(localStorage.getItem(line));
        if (skills) {
            skills.map(skill => {
                if(skill.includes(':')) {
                    skillPointsCount += parseInt(skill.split(':')[1]);
                } else {
                    skillPointsCount ++;
                }
                return null;
            })
        }
        return skillPointsCount;
    }

    return (
        <div>
            <SkillPointsNeeded skillPoints={skillPoints} ClassSkillLines={ClassSkillLines} SkillLines={SkillLines} />
            <nav>
                <div>
                    <ClassMenuItem key='class' toggleSubmenu={toggleSubmenu} classID={props.classID} data={ClassSkillLines} name={props.className && props.classID ? props.className : 'Class'} id={props.classSlug && props.classID ? props.classSlug : 'class'} skillPoints={skillPoints} />
                    <div id="classes" className="submenu">
                        {props.classID ?
                            ClassSkillLines.map(charClass => (
                                charClass.id === props.classID &&
                                charClass.skill_lines.map(skillLine => (
                                        <SubmenuItem key={skillLine.id} skillPoints={skillPoints} handleClick={props.chooseSkills}
                                        chooseSkills={props.chooseSkills} name={skillLine.name} id={skillLine.id} />
                                    ))
                                ))
                            :
                                ClassSkillLines.map(charClass => (
                                    <ClassSubmenuItem key={charClass.id} skillPoints={skillPoints} selectClass={selectClass}
                                    chooseSkills={props.chooseSkills} name={charClass.name} slug={charClass.slug} id={charClass.id}
                                    data={charClass} />
                                ))
                        }
                        {props.classID ? <ChangeClass changeClass={changeClass} /> : ''}
                    </div>
                </div>

                {SkillLines.map(skillLine => (
                    <div key={skillLine.id}>
                        <MenuItem handleClick={toggleSubmenu} data={skillLine} classID={props.classID} skillPoints={skillPoints} selectClass={selectClass} />
                        <div className="submenu">
                            {skillLine.skill_lines.map(skillLine => (
                                <SubmenuItem key={skillLine.id} skillPoints={skillPoints} handleClick={props.chooseSkills}
                                chooseSkills={props.chooseSkills} name={skillLine.name} id={skillLine.id} />
                            ))}
                        </div>
                    </div>
                ))}

                <div className="clear-selections">
                    <button className="btn reset" onClick={resetAll}>Reset All Selections</button>
                </div>
            </nav>
        </div>
    );
}

export default Component;
