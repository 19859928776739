import React from 'react';

function Component(props) {
    let count = 0;

    props.data.map(submenuItem => {
        if (submenuItem.id === props.classID) {
            submenuItem.skill_lines.map((skillLine) => count += props.skillPoints(skillLine.id))
        }
        return null;
    })

    return (
        <div className="menu-item">
            <div className={`icon ${props.id}`}></div>
            <button className="trigger" onClick={props.toggleSubmenu}>{props.name}</button>
            <span className="number">{count > 0 ? count : '' }</span>
        </div>
    );
}

export default Component;
