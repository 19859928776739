import React from 'react';

function Component(props) {

    return (
        <footer className="footer">
            <p>Please send all fedback and enquiries to <a href="mailto:info@esobuilder.com">info@esobuilder.com</a></p>
        </footer>
    );
}

export default Component;
