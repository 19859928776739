import React, { useState, useEffect } from 'react';

import Morph from "./Morph";

function Component(props) {
    const [selected, setSelected] = useState(false);
    const [hasMorph, setHasMorph] = useState(false);
    const [selectedMorph, setSelectedMorph] = useState('');

    //  if selected skills is empty (e.g. after reset button pressed)
    useEffect(() => {
        if (props.selectedSkills.length === 0) {
            setSelected(false);
            setHasMorph(false);
            setSelectedMorph('');
        }
    }, [props.selectedSkills])

    useEffect(() => {
        //  if skill is in selected skills update state to setup the UI to reflect the selections
        if (props.selectedSkills.includes(props.data.id)) {
            setSelected(true);
        }
        //  if skill's morph is in selected skills update state to setup the UI to reflect the selections
        props.data.morphs.map(morph => {
            if (props.selectedSkills.includes(morph.id)) {
                setHasMorph(true);
                setSelectedMorph(morph.id);
            }
            return null;
        })

        // when main skill is not selected deselect all morphs
        if (selected === false) {
            setHasMorph(false);
            setSelectedMorph('');
        }
    }, [selected, props.selectedSkills, props.data.id, props.data.morphs])

    const selectMain = () => {
        setSelected(!selected);
        props.updateSelectedSkills(props.data.id);
        // when the morph changes remove previous morph from selected skills list
        if (selectedMorph) {
            props.updateSelectedSkills(selectedMorph);
        }
    }

    return (
        <div className="skill-container">
            <div className={`skill ${props.data.resource_type} main-skill
            ${selected ? 'selected' : ''} ${hasMorph ? 'morph-selected' : ''}`}>
                <div className="row">
                    <button className="btn" value={props.data.id} onClick={selectMain}></button>
                    <img className="icon" src={`/images/icons/${props.data.id}.png`} alt="" />
                    <div className="name">{props.data.name}<div className="type">{props.data.resource_type}</div></div>
                </div>
            </div>
            <div className="morphs">
                {props.data.morphs.map((morph) => {
                    return (
                        <Morph key={morph.id} data={morph} setMainHasMorph={setHasMorph} mainHasMorph={hasMorph}
                        mainSelected={selected} setSelectedMorph={setSelectedMorph} selectedMorph={selectedMorph}
                        updateSelectedSkills={props.updateSelectedSkills} selectedSkills={props.selectedSkills}
                        classID={props.classID} />
                    );
                })}
            </div>
        </div>
    );
}

export default Component;
